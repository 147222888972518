<template>
  <div class="result-info">
    <div v-for="(item, index) in infoList[activeIndex]" :key="index" class="info-item" :class="{'last': index == infoList[activeIndex].length - 1}">
      <div class="info-title">{{ item.title }}</div>
      <template v-if="item.type == 'field'">
        <div v-for="(val, i) in item.valueList" class="field-item" :key="i">
          <div style="color: #666;min-width: 100px;">{{ val.label }}</div>
          <div style="color: #333">{{ val.value }}</div>
        </div>
      </template>
      <div v-if="item.type == 'label'" style="display:flex;flex-wrap: wrap;">
        <div v-for="(val, i) in item.valueList" class="label-item" :key="i">
          {{ val.label }}
        </div>
      </div>
      <div v-if="item.type == 'car'" style="display:flex;flex-wrap: wrap;">
        <div v-for="(val, i) in item.valueList" class="car-item" :key="i">
          <div class="top">{{ val.label }}</div>
          <div class="bottom">{{ val.value }}</div>
        </div>
      </div>
      <div v-if="item.type == 'visit'" style="margin-top:24px">
        <div v-for="(val, i) in item.valueList" class="time-item" :key="i">
          <div style="color:#333">{{ val.label }}</div>
          <div class="icon" :class="{'icon-line': i != item.valueList.length - 1}">
            <div class="icon-border"></div>
          </div>
          <div style="color:#666">{{ val.value }}</div>
        </div>
      </div>
      <template v-if="item.type == 'house'">
        <div v-for="(val, i) in item.valueList" :key="i" style="margin-bottom: 20px;border-bottom: 1px solid #000;" :class="{'lastHouse': i == item.valueList.length - 1}">
          <div class="field-item">
            <div style="color: #666">房屋名称</div>
            <div style="color: #333">{{ val.fullName }}</div>
          </div>
          <div class="field-item">
            <div style="color: #666">房屋性质</div>
            <div style="color: #333">{{ val.useTypeName }}</div>
          </div>
          <div class="field-item">
            <div style="color: #666">房屋面积</div>
            <div style="color: #333">{{ val.areas }}</div>
          </div>
          <div class="field-item">
            <div style="color: #666">是否居住</div>
            <div style="color: #333">{{ val.nowLive == 1 ? '是' : '否' }}</div>
          </div>
          <div class="field-item last">
            <div style="color: #666">与房主关系</div>
            <div style="color: #333">{{ val.relationshipName }}</div>
          </div>
          <div v-if="val.labels && val.labels.length > 0" style="display:flex;flex-wrap: wrap;">
            <div v-for="(labelVal, j) in val.labels" class="label-item" :key="j">
              {{ labelVal }}
            </div>
          </div>
        </div>
      </template>
      <van-empty description="暂无数据" v-if="item.valueList.length == 0"/>
    </div>

    <div class="info-item select">
      <div class="info-title">选择信息</div>
      <div class="select-btn">
        <div v-for="(item, index) in btnList" :key="index" class="btn-item"
             :class="{'active-btn' : activeIndex == index}" @click="selectInfo(index)">{{ item }}
        </div>
        <div class="btn-item h1"></div>
        <div class="btn-item h1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {formatLabel, getImageStream} from '@/utils/index.js'
import {desensitization} from "@/utils/utils";

export default {
  data() {
    return {
      id: '',
      idNumber: '',
      orgId: '',
      infoList: [[{
        type: 'field',
        title: '基础信息',
        valueList: []
      }], [{
        type: 'field',
        title: '联系方式',
        valueList: []
      }], [{
        type: 'label',
        title: '标签信息',
        valueList: []
      }], [{
        type: 'car',
        title: '车辆信息',
        valueList: []
      }], [{
        type: 'visit',
        title: '走访管理',
        valueList: []
      }, {
        type: 'visit',
        title: '问题管理',
        valueList: []
      }, {
        type: 'visit',
        title: '报修管理',
        valueList: []
      }, {
        type: 'visit',
        title: '活动管理',
        valueList: []
      }], [{
        type: 'field',
        title: '防疫情况',
        valueList: []
      }], [{
        type: 'field',
        title: '配偶信息',
        valueList: []
      }, {
        type: 'field',
        title: '父母信息',
        valueList: []
      }, {
        type: 'field',
        title: '子女信息',
        valueList: []
      }, {
        type: 'field',
        title: '其他信息',
        valueList: []
      }], [{
        type: 'house',
        title: '房屋信息',
        valueList: []
      }]],
      activeIndex: 0,
      btnList: ['个人信息', '联系方式', '居民标签', '车辆信息', '社区活跃度', '防疫情况', '家庭关系', '房屋信息']
    }
  },
  methods: {
    selectInfo (index) {
      this.activeIndex = index
    },
    getResInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/info`),
        method: 'post',
        params: this.$http.adornParams({
          id: parseInt(this.id),
          orgId: this.orgId,
          userType: 1
        })
      }).then(({data}) => {
        this.$toast.clear()
        console.log(data)
        if (data.code == 0) {
          this.infoList[0][0].valueList = [{
            label: '姓名',
            value: data.userInfo.name || '未知'
          },{
            label: '曾用名',
            value: data.userInfo.usedName || ''
          }, {
            label: '性别',
            value: data.userInfo.sex == 1 ? '男' : data.userInfo.sex == 2 ? '女' : '未知'
          }, {
            label: '国籍',
            value: data.userInfo.nationalityStr || '未知'
          }, {
            label: '民族',
            value: data.userInfo.nationStr || '未知'
          }, {
            label: '籍贯',
            value: data.userInfo.nativePlace || '未知'
          },{
            label: '出生年月',
            value: data.userInfo.birthday || '未知'
          }, {
            label: '年龄',
            value: data.userInfo.age || '未知'
          }, {
            label: '证件类型',
            value: data.userInfo.idNumberTypeStr || '未知'
          }, {
            label: '证件号码',
            value: data.userInfo.idNumber || '未知'
          }, {
            label: '婚姻状况',
            value: data.userInfo.marriageStr || '未知'
          }, {
            label: '学历',
            value: data.userInfo.educationStr || '未知'
          }, {
            label: '户籍所在地',
            value: data.userInfo.regAddress || '未知'
          }, {
            label: '现居住地',
            value: data.userInfo.nowAddress || '未知'
          }, {
            label: '工作单位',
            value: data.userInfo.company || '未知'
          }, {
            label: '备注',
            value: data.userInfo.emMobile || ''
          }]
          this.infoList[1][0].valueList = [
            {
              label: '手机号码',
              value: data.userInfo.mobile || '未知'
            }, {
              label: '固定电话',
              value: data.userInfo.fixedLine || '未知'
            }, {
              label: '紧急联系人',
              value: data.userRes.emContact || '未知'
            }, {
              label: '紧急联系人电话',
              value: data.userRes.emMobile || '未知'
            }
          ]
          if (data.resLabels) {
            this.infoList[2][0].valueList = this.infoList[2][0].valueList = formatLabel(data.resLabels, true)
          }
        }
      })
    },
    getResCar() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/devicecar/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          searchValue: this.$route.query.name
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.infoList[3][0].valueList = this.infoList[3][0].valueList = data.page.list && data.page.list.map(item => {
            return {
              label: item.typeStr,
              value: item.carNumber
            }
          })
        }
      })
    },
    getVisitList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/visit/list'),
        method: 'get',
        params: this.$http.adornParams({
          page: 1,
          limit: 3,
          orgId: this.orgId,
          idNumber: this.idNumber
        })
      }).then(({data}) => {
        if (data.code == 0 && data.page && data.page.list) {
          this.infoList[4][0].valueList = data.page.list.map(item => {
            return {
              label: item.createTime,
              value: '社工' + item.createUserName + '进行了' + item.visitTypeStr
            }
          })
        }
        this.$toast.clear()
      })
    },
    getActiviList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/getData'),
        method: 'get',
        params: this.$http.adornParams({
          page: 1,
          limit: 3,
          orgId: this.orgId,
          userId: this.id
        })
      }).then(({data}) => {
        if (data.code == 0 && data.page && data.page.list) {
          this.infoList[4][3].valueList = data.page.list && data.page.list.map(item => {
            return {
              label: item.createTime,
              value: '参加了' + item.title
            }
          })
        }
        this.$toast.clear()
      })
    },
    getRepairList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/repair/info/list'),
        method: 'get',
        params: this.$http.adornParams({
          page: 1,
          limit: 3,
          orgId: this.orgId,
          userId: this.id
        })
      }).then(({data}) => {
        if (data.code == 0 && data.page && data.page.list) {
          this.infoList[4][2].valueList = data.page.list && data.page.list.map(item => {
            return {
              label: item.createTime,
              value: item.repairDes
            }
          })
        }
        this.$toast.clear()
      })
    },
    getProblemList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/list'),
        method: 'get',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: this.orgId,
          userId: this.id
        })
      }).then(({data}) => {
        if (data.code == 0 && data.list && data.list.list) {
          this.infoList[4][1].valueList = data.list.list && data.list.list.map(item => {
            return {
              label: item.createDate,
              value: item.content
            }
          })
        }
        this.$toast.clear()
      })
    },
    getVaccData() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/appVaccination/list'),
        method: 'get',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: this.orgId,
          userIds: this.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          if (data.page.list.length > 0) {
            let info = data.page.list[0]
            this.infoList[5][0].valueList = [{
              label: '接种次数',
              value: info.times || 0,
            }, {
              label: '第一针时间',
              value: info.firstTime || '暂无',
            }, {
              label: '第二针时间',
              value: info.secondTime,
            }, {
              label: '第三针时间',
              value: info.thirdTime,
            }, {
              label: '核酸检测时间',
              value: info.checkLastTime,
            }, {
              label: '核酸检测结果',
              value: info.checkResult,
            }, {
              label: '健康码颜色',
              value: info.codeColorStr,
            }, {
              label: '健康码更新时间',
              value: info.codeColorTime,
            }, {
              label: '抗原自测结果',
              value: info.result,
            }, {
              label: '抗原自测时间',
              value: info.result,
            }]
          }
        }
        this.$toast.clear()
      })
    },
    getFamilyData() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/getUserHouse'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.orgId,
          userId: this.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          data.map.familyVOS.map(item => {
            if (item.relationStr == '配偶') {
              this.infoList[6][0].valueList = this.infoList[6][0].valueList.concat([{
                label: '姓名',
                value: item.name
              }, {
                label: '手机号',
                value: item.mobile
              }, {
                label: '证件号码',
                value: item.idCard
              }])
            } else if (item.relationStr == '父母') {
              this.infoList[6][1].valueList = this.infoList[6][1].valueList.concat([{
                label: '姓名',
                value: item.name
              }, {
                label: '手机号',
                value: item.mobile
              }, {
                label: '证件号码',
                value: item.idCard
              }])
            } else if (item.relationStr == '子女') {
              this.infoList[6][2].valueList = this.infoList[6][2].valueList.concat([{
                label: '姓名',
                value: item.name
              }, {
                label: '手机号',
                value: item.mobile
              }, {
                label: '证件号码',
                value: item.idCard
              }])
            } else {
              this.infoList[6][3].valueList = this.infoList[6][3].valueList.concat([{
                label: '家庭关系',
                value: item.relationStr
              }, {
                label: '姓名',
                value: item.name
              }, {
                label: '手机号',
                value: item.mobile
              }, {
                label: '证件号码',
                value: item.idCard
              }])
            }
          })
        }
        this.$toast.clear()
      })
    },
    getReshouse() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/house/list"),
        method: "post",
        params: this.$http.adornParams({
          orgId: this.orgId,
          userId: this.id,
          page: 1,
          limit: 100,
        }),
      }).then(
          ({data}) => {
            if (data.code == 0) {
              data.page.list.forEach((item) => {
                let house = {
                  fullName: item.fullName,
                  useTypeName: item.useTypeName,
                  relationshipName: item.relationshipName,
                  nowLive: item.nowLive,
                  areas: item.areas,
                  labels: item.labels ? item.labels.split('；') : []
                }
                this.infoList[7][0].valueList.push(house)
              })
              console.log(this.infoList[7][0].valueList)
            }
          },
          (err) => {
            this.$toast.clear();
          }
      )
    }
  },
  created() {
    this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true})
    this.id = this.$route.query.id
    this.idNumber = this.$route.query.idNumber
    this.orgId = this.$route.query.orgId
    this.getResInfo()
    this.getResCar()
    this.getActiviList()
    this.getVisitList()
    this.getRepairList()
    this.getProblemList()
    this.getVaccData()
    this.getFamilyData()
    this.getReshouse()
  }
}
</script>
<style scoped lang="scss">
.van-empty {
  padding: 0;
}

.last {
  border-bottom: none;
  margin-bottom: 20px;
}
::v-deep .van-empty__image {
  width: 100px;
  height: 100px;
}

.result-info {
  padding-bottom: 430px;
}

.info-item {
  border-top: 24px solid #f5f5f5;
  font-size: 28px;
  background-color: #fff;
  width: 100%;
  padding: 22px 30px 0;

  .info-title {
    font-weight: bold;
    margin-bottom: 22px;
  }

  .field-item {
    padding: 24px 0;
    border-bottom: 1px solid #EDEDED;
    display: flex;
    justify-content: space-between;
  }

  .lastHouse {
    border-bottom: none !important;
  }

  .label-item {
    color: #F24C48;
    height: 56px;
    line-height: 56px;
    background: rgba(69, 129, 248, 0.1);
    border-radius: 12px;
    border: 2px solid #F24C48;
    padding: 0 24px;
    margin: 0 24px 24px 0;
    font-size: 24px;
  }

  .car-item {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #F24C48;
    margin: 0 24px 24px 0;
    font-size: 24px;
    overflow: hidden;

    .top {
      color: #F24C48;
      height: 56px;
      line-height: 56px;
      padding: 0 24px;
      width: 100%;
      text-align: center;
    }

    .bottom {
      background: #F24C48;
      color: #fff;
      height: 56px;
      line-height: 56px;
      padding: 0 24px;
      width: 100%;
      text-align: center;
    }
  }

  .time-item {
    font-size: 24px;
    display: flex;
    padding-bottom: 48px;

    .icon-border {
      background: #fff;
      height: 100%;
      border-radius: 50%;
    }

    .icon {
      padding: 2px;
      box-sizing: border-box;
      margin: 0 48px;
      position: relative;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      box-shadow: 0px 4px 16px 0px rgba(69, 129, 248, 0.4), 0px 4px 30px 0px rgba(45, 36, 190, 0.5);
      background-image: linear-gradient(135deg, rgba(100, 149, 255, 1), rgba(145, 136, 255, 1));

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: linear-gradient(132deg, #6096FF 0%, #9488FF 100%);
        box-shadow: 0px 4px 16px 0px rgba(69, 129, 248, 0.4), 0px 4px 30px 0px rgba(45, 36, 190, 0.5);
      }
    }

    .icon-line {
      &::after {
        content: '';
        position: absolute;
        top: 26px;
        width: 2px;
        left: 50%;
        transform: translateX(-50%);
        height: 50px;
        background: linear-gradient(132deg, #6096FF 0%, #9488FF 100%);
        box-shadow: 0px 4px 30px 0px rgba(45, 36, 190, 0.5);
      }
    }
  }

  .select-btn {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .btn-item {
      margin-top: 24px;
      width: 192px;
      height: 72px;
      line-height: 72px;
      border-radius: 36px;
      border: 1px solid #EEEEEE;
      text-align: center;
    }

    .h1 {
      height: 1px;
      visibility: hidden
    }

    .active-btn {
      color: #fff;
      background: linear-gradient(132deg, #6096FF 0%, #9488FF 100%);
      box-shadow: 0px 2px 15px 0px rgba(45, 36, 190, 0.5);
      border: none;
    }
  }

}

.select {
  padding-top: 24px;
  position: fixed;
  bottom: 0;
  padding-bottom: 40px;
}
</style>
